<template>
  <div class="pages">
    <div class="left">
      <img class="logo" src="../../static/icon/logo.png">
      <span>财讯</span>
    </div>
    <div class="main">
      <span class="main-tips" v-if="!forgetPsw">欢迎使用</span>
      <span class="main-title" v-if="!forgetPsw">青峰见财讯平台</span>
      <div class="main-title" v-if="forgetPsw">
        <img @click="forgetPsw = false" src="../../static/icon/go-back.png">
        <span>忘记密码</span>
      </div>
      <div class="login-type" v-if="!forgetPsw">
        <span class="type-span" :class="loginType === 0 ? 'type-span-active' : ''" @click="loginType = 0">密码登录</span>
        <span class="type-span" :class="loginType === 1 ? 'type-span-active' : ''" @click="loginType = 1">验证码登录</span>
      </div>
      <VueWxlogin v-if="loginType === 2" :appid="appId" scope="snsapi_login" :redirect_uri="redirectUri"
        :state="state" :self_redirect="false"></VueWxlogin>
      <div class="input-box" v-if="loginType !== 2">
        <img src="../../static/icon/user-icon.png">
        <input v-model="userName" type="text" placeholder="请输入账号">
      </div>
      <div class="input-box" v-if="loginType === 0 && !forgetPsw">
        <img src="../../static/icon/psd-icon.png">
        <input v-model="password" :type="isShowpsw ? 'text' : 'password'" placeholder="请输入密码">
        <img style="cursor: pointer;width: 16px;height: 12px;" @click="isShowpsw = !isShowpsw"
          src="../../static/icon/yan-icon.png">
      </div>
      <!-- <span v-if="forgetPsw" class="forget-code-phone">+86 {{ userName }}</span> -->
      <div class="input-box" v-if="loginType === 1 || forgetPsw">
        <img src="../../static/icon/psd-icon.png">
        <input v-model="code" type="text" placeholder="请输入验证码">
        <span class="get-code" @click="getCode">{{ smsCodeTrue ? '获取验证码' : smsCodeTime + '秒后再次获取' }}</span>
      </div>
      <div class="input-box" v-if="forgetPsw">
        <input v-model="newPassword" :type="isShowpsw1 ? 'text' : 'password'" placeholder="请输入新密码">
        <img style="cursor: pointer;width: 16px;height: 12px;" @click="isShowpsw1 = !isShowpsw1"
          src="../../static/icon/yan-icon.png">
      </div>
      <div class="input-box" v-if="forgetPsw">
        <input v-model="newPassword1" :type="isShowpsw2 ? 'text' : 'password'" placeholder="请再次输入新密码">
        <img style="cursor: pointer;width: 16px;height: 12px;" @click="isShowpsw2 = !isShowpsw2"
          src="../../static/icon/yan-icon.png">
      </div>
      <div class="btn" @click="forgetPassword" v-if="forgetPsw">
        <span>确定，返回登录</span>
      </div>
      <div class="remember-psd" v-if="!forgetPsw && loginType !== 2">
        <span @click="forgetPsw = true">忘记密码？</span>
      </div>
      <div class="btn" @click="login" v-if="!forgetPsw && loginType !== 2">
        <span>登录</span>
        <img src="../../static/icon/login-icon.png">
      </div>
      <div class="protocol-box" v-if="!forgetPsw && loginType !== 2">
        <el-checkbox v-model="protocolChecked"></el-checkbox>
        <span class="protocol">未注册手机验证后自动登录，注册即代表同意<span class="blue" @click="goProtocol(1)">《用户协议》</span><span
            class="blue" @click="goProtocol(2)">《隐私协议》</span></span>
      </div>
      <div class="other-login-type" v-if="loginType !== 2">
        <div class="other-login-type-len"></div>
        <span>其他登录方式</span>
        <div class="other-login-type-len"></div>
      </div>
      <img v-if="loginType !== 2" class="other-login-type-img" @click="chooseWechatLogin" src="../../static/icon/wechat-icon.png">
    </div>
    <div class="footer-main">
      <span>版权所有©财讯平台 青峰见网络科技（苏州）有限公司 </span>
      <img src="../../static/icon/beian.png">
      <span>苏公网安备32059002004804号</span>
      <span>苏ICP备2021012769号</span>
    </div>
  </div>
</template>

<script>
import { login, smsCode, getUserInfo, forgetPassword } from '@/api/login.js'
import { getInfo } from '@/api/homeRight.js'
import { validatePhoneNumber } from '@/utils/utils.js'
import VueWxlogin from 'vue-wxlogin';
export default {
  data() {
    return {
      loginType: 0,//登录方式
      userName: '',//用户名
      password: '',//密码
      code: '',//验证码
      checked: false,//是否记住密码
      smsCodeTrue: true,//是否可以发送验证码
      smsCodeTime: null,//验证码倒计时
      protocolChecked: false,//是否同意协议
      forgetPsw: false,//是否处于忘记密码状态
      isShowpsw: false,//登录密码是否明文
      isShowpsw1: false,//新密码是否明文
      newPassword: '',//新密码
      isShowpsw2: false,//确认密码是否明文
      newPassword1: '',//确认新密码
      appId:'',
      // redirectUri: encodeURI('https://www.xdigitallife.com/transfer'),
      redirectUri: encodeURI(window.location.href.split(this.$route.fullPath)[0] + '/transfer'),
      state: ''
    };
  },
  components: {
    VueWxlogin
  },
  created() {
    if (this.$route.query.type) {
      this.forgetPsw = true
    }
    this.getWxLoginconfig()
  },
  methods: {
    /**
     * 切换微信扫码登录
     */
    chooseWechatLogin(){
      if (!this.protocolChecked) {
        this.$message.error('请先阅读并同意隐私保护指引和协议');
        return
      }
      this.loginType = 2
    },
    /**
     * 获取code
     */
    getWxLoginconfig() {
      getInfo('WxLoginConfig').then(res => {
        this.appId = res.appId
        this.state = res.state + this.$route.fullPath
      })
    },
    /**
     * 跳转隐私协议
     */
    goProtocol(type) {
      this.$router.push({ path: '/protocol', query: { type: type } })
    },
    /**
     * 获取验证码
     */
    getCode() {
      if (!this.smsCodeTrue) {
        this.$message.error('请' + this.smsCodeTime + '秒后再次发送');
        return
      }
      if (!validatePhoneNumber(this.userName)) {
        this.$message.error('请输入正确手机号');
        return
      }
      smsCode({
        phone: this.userName
      }).then(() => {
        this.$message.success('发送验证码成功')
        this.smsCodeTrue = false
        this.smsCodeTime = 60
        this.timeDown = setInterval(() => {
          if (this.smsCodeTime !== 0) {
            this.smsCodeTime--
          } else {
            clearInterval(this.timeDown)
            this.smsCodeTrue = true
            this.smsCodeTime = null
          }
        }, 1000);
      })
    },
    /**
     * 忘记密码提交
     */
    forgetPassword() {
      if (!this.code) {
        this.$message.error('请输入验证码');
        return
      }
      if (!this.newPassword) {
        this.$message.error('请输入新密码');
        return
      }
      if (!this.newPassword1 || this.newPassword !== this.newPassword1) {
        this.$message.error('两次密码输入不一致');
        return
      }
      forgetPassword({
        phoneNumber: this.userName,
        verificationCode: this.code,
        newPassword: this.newPassword
      }).then(() => {
        this.$message.success('成功修改密码，请重新登录');
        clearInterval(this.timeDown)
        this.smsCodeTrue = true
        this.smsCodeTime = null
        this.code = ''
        this.forgetPsw = false
      })
    },
    login() {
      if (!this.protocolChecked) {
        this.$message.error('请先阅读并同意隐私保护指引和协议');
        return
      }
      if (!this.userName) {
        this.$message.error('请输入用户名');
        return
      }
      if (this.loginType === 0) {
        if (!this.password) {
          this.$message.error('请输入密码');
          return
        }
        login({
          userName: this.userName,
          password: this.password
        }).then(res => {
          this.setToken(res);
          getUserInfo().then(userInfo => {
            this.setStorage('userId', userInfo.id);
            this.$router.push({ path: "/" });
          })
        })
      } else {
        if (!validatePhoneNumber(this.userName)) {
          this.$message.error('请输入正确手机号');
          return
        }
        if (!this.code) {
          this.$message.error('请输入验证码');
          return
        }
        login({
          phoneNumber: this.userName,
          verificationCode: this.code
        }).then((res) => {
          this.setToken(res);
          getUserInfo().then(userInfo => {
            this.setStorage('userId', userInfo.id);
            this.$router.push({ path: "/" });
          })
        })
      }
    },
  },
};
</script>

<style scoped>
.pages {
  display: flex;
}

.left {
  background-color: #8E4F4F;
  width: 510px;
  height: 100vh;
  padding: 34px;
  display: flex;
}

.left .logo {
  width: 40px;
  height: 40px;
}

.left span {
  font-size: 21px;
  margin-left: 8px;
  color: #FFFFFF;
  line-height: 40px;
  font-family: ZHSRXT2011-GB, ZHSRXT2011-GB;
}

.main {
  width: calc(100vw - 510px);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main-tips {
  color: #636363;
  font-size: 16px;
}

.main-title {
  color: #333333;
  margin-top: 12px;
  font-size: 32px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 480px;
}

.main-title img {
  width: 36px;
  position: absolute;
  left: 0;
  top: 10px;
  cursor: pointer;
}

.main-title span {
  color: #333333;
  font-size: 32px;
}

.login-type {
  width: 480px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 46px;
  margin-top: 72px;
  margin-bottom: 16px;
}

.login-type span {
  font-size: 16px;
  cursor: pointer;
}

.login-type .type-span {
  color: #999999;
}

.login-type .type-span-active {
  color: #DD0722;
}

.forget-code-phone {
  width: 480px;
  margin-top: 68px;
  color: #020C17;
  font-size: 15px;
}

.input-box {
  margin-top: 28px;
  width: 480px;
  border-radius: 28px;
  height: 56px;
  background-color: #F0F2F5;
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.input-box img {
  width: 28px;
  height: 28px;
}

.input-box input {
  margin-left: 10px;
  flex: 1;
  height: 26px;
  border: none;
  background-color: #F0F2F5;
}

.input-box .get-code {
  font-size: 16px;
  color: #4181B8;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
}

.remember-psd {
  margin-top: 14px;
  width: 480px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.remember-psd span {
  color: #999999;
  font-size: 14px;
  margin-left: 8px;
  cursor: pointer;
}

.btn {
  width: 480px;
  height: 46px;
  background-color: #DD0722;
  border-radius: 28px;
  margin-top: 50px;
  cursor: pointer;
}

.btn span {
  color: #FFFFFF;
  font-size: 18px;
  margin-right: 6px;
}

.btn img {
  width: 19px;
  height: 19px;
}

.protocol-box {
  display: flex;
  align-items: center;
  margin-top: 14px;
}

.protocol {
  margin-left: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #999999;
}

.protocol .blue {
  cursor: pointer;
  color: #1186E1 !important;
}

.other-login-type{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
}
.other-login-type-len{
  border-top: 1px solid;border-color: #ebeced;width: 160px;
}
.other-login-type span{
    color: #9196a1;
    font-size: 16px;
    margin-left: 16px;
    margin-right: 16px;
}
.other-login-type-img{
  margin-top: 28px;
  width: 42px;
  cursor: pointer;
}

.footer-main {
  position: fixed;
  bottom: 0;
  left: 510px;
  width: calc(100vw - 510px);
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-main img {
  width: 20px;
}

.footer-main span {
  margin: 0 12px;
  color: #000000;
  font-size: 12px;
}
</style>
